export const TIME_INTERVAL_TYPEWRITER = 4; //miliseconds
export const CHAT_TYPE = {
  PRIVATE: "private",
  PUBLIC: "public",
  PUBLIC_O3: "public_O3",
  PRIVATE_O3: "private_O3",
};

export const ROLE = {
  ADMIN: "admin",
  USER: "user",
}