import React from "react";
import { useSelector } from "react-redux";
import { commonIcon } from "../../asset";

const BranchText = ({ hasChatType }) => {
  const chatType = useSelector((state) => state.chat.chatType);

  return (
    <div className="flex items-center justify-center gap-2 capitalize">
      <img
        src={commonIcon.chatGeminiIcon}
        className="!size-6"
        alt="Gospel Truth"
      ></img>
      <p className="flex items-center gap-1 !font-semibold whitespace-nowrap text-base">
        <span className="text-[#231F20]">Gospel</span>
        <span className="text-[#7B7C7F]">Truth</span>
        {hasChatType && chatType && (
          <span className="text-indigo-900">{chatType.replace("_", " ")}</span>
        )}
      </p>
    </div>
  );
};

export default BranchText;
