import geminiIcon from "./gemini_sparkle_blue_33c17e77c4ebbdd9490b683b9812247e257b6f70.svg";
import advanceGeminiIcon from "./gemini_sparkle_red_4ed1cbfcbc6c9e84c31b987da73fc4168aec8445.svg";
import avatarIcon from "./avater-icon.png";
import defaultAvatar from "./default-avatar.png";
// import chatGeminiIcon from "./bard_sparkle_v2.svg";
import chatGeminiIcon from "./logo.png";
import fullLogo from "./gtruth-logo.png";
import fullLogo2 from "./gtruth-logo2.png";
import geminiLaoder from "./bard_sparkle_processing_v2_loader.gif";
import googleLogo from "./icons8-google-48.png";
import ytIcon from "./icons8-youtube-48.png";
import flightIcon from "./icons8-flight-64.png";
import mapIcon from "./icons8-google-maps-48.png";
import hotelIcon from "./icons8-hotel-48.png";
import sportsIcon from "./icons8-man-winner-48.png";
import googleBigIcon from "./icons8-google-144.png";

import { darkIcon } from "./darkIcon/darkIcon";
import { lightIcon } from "./lightIcon/lightIcon";

import emailIcon from "./icons/email.png";
import sendIcon from "./icons/send.png";
import menuIcon from "./icons/menu.png";
import threedottIcon from "./icons/threedot.png";
import addtIcon from "./icons/add.png";
import stopIcon from "./icons/stop.png";
import chatIntro1 from "./chat-introduce1.png";
import chatIntro2 from "./chat-introduce2.webp";
import chatIntro3 from "./chat-introduce3.webp";
import successIcon from "./icons/icons8-success-480.png";

import tutorialIcon from "./tutorial/6172536.gif";



export const commonIcon = {
  geminiIcon,
  advanceGeminiIcon,
  avatarIcon,
  chatGeminiIcon,
  geminiLaoder,
  googleLogo,
  ytIcon,
  flightIcon,
  mapIcon,
  hotelIcon,
  googleBigIcon,
  defaultAvatar,
  sendIcon,
  menuIcon,
  threedottIcon,
  addtIcon,
  stopIcon,
  chatIntro1,
  chatIntro2,
  chatIntro3,
  fullLogo,
  fullLogo2,
  emailIcon,
  successIcon,
  tutorialIcon
};

export const themeIcon = () => {
  const localTheme = localStorage.getItem("theme") || "light";
  const icon = localTheme === "dark" ? darkIcon : lightIcon;

  return icon;
};

export const suggestPrompt = [
  {
    id: 1,
    sort: "Where is heaven?",
    long: "Where is heaven?",
    icon: "ideaIcon",
  },
  {
    id: 2,
    sort: "What is the meaning of salvation?",
    long: "What is the meaning of salvation?",
    icon: "codeIcon",
  },
  {
    id: 3,
    sort: "Can we ever be confident that we will be saved?",
    long: "Can we ever be confident that we will be saved?",
    icon: "ideaIcon",
  },
  {
    id: 4,
    sort: "What role does faith play in salvation?",
    long: "What role does faith play in salvation?",
    icon: "ideaIcon",
  },
  {
    id: 5,
    sort: "Is salvation by faith alone, or do works matter?",
    long: "Is salvation by faith alone, or do works matter?",
    icon: "navigateIcon",
  },
  {
    id: 6,
    sort: "What does the Bible say about eternal life?",
    long: "What does the Bible say about eternal life?",
    icon: "ideaIcon",
  },
  {
    id: 7,
    sort: "Can someone lose their salvation?",
    long: "Can someone lose their salvation?",
    icon: "navigateIcon",
  },
  {
    id: 9,
    sort: "How does one repent and be forgiven for their sins?",
    long: "How does one repent and be forgiven for their sins?",
    icon: "writeIcon",
  },
  {
    id: 10,
    sort: "What are the signs of being saved?",
    long: "What are the signs of being saved?",
    icon: "ideaIcon",
  },
  {
    id: 11,
    sort: "How does grace relate to salvation?",
    long: "How does grace relate to salvation?",
    icon: "writeIcon",
  },
  {
    id: 12,
    sort: 'What does it mean to be "born again"?',
    long: 'What does it mean to be "born again"?',
    icon: sportsIcon,
  },
  {
    id: 13,
    sort: "What is the significance of Jesus in the process of salvation?",
    long: "What is the significance of Jesus in the process of salvation?",
    icon: "writeIcon",
  },
];
