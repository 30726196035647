/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Sidebar.module.css";
import { themeIcon } from "../../asset";
import { commonIcon } from "../../asset";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "../../store/ui-gemini";
import { useMemo, useEffect, useState, useRef } from "react";
import { chatAction } from "../../store/chat";
import {
  deleteRecentChat,
  deleteRecentChats,
  getRecentPaging,
} from "../../store/chat-action";
import { useNavigate } from "react-router-dom";
import {
  IconClose,
  IconTrash,
  IconArrowDown,
  IconSpinner,
  IconFAQ,
} from "../Ui/Icon";
import DropdownRecentChat from "./Dropdown";
import ConfirmDeletePopup from "../Ui/ConfirmPopup";
import { calculatePage } from "../../utils/paging";

const INIT_LIMIT = 5;
const LOADMORE_LIMIT = 20;

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSidebarLong = useSelector((state) => state.ui.isSidebarLong);
  const isNewChat = useSelector((state) => state.chat.newChat);
  const recentChat = useSelector((state) => state.chat.recentChat);
  const [isActiveChat, setIsActiveChat] = useState("");
  const chatHistoryId = useSelector((state) => state.chat.chatHistoryId);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChekedAll, setIsChekedAll] = useState(false);
  const [loadingRecent, setLoadingRecent] = useState(false);

  const [paging, setPaging] = useState({
    page: 1,
    limit: INIT_LIMIT,
    total: 0,
    totalPages: 0,
  });

  const itemRecentClick = (id) => {
    if (selectMultiple) {
      handleCheckboxChange(id);
    } else {
      setIsActiveChat(id);
      navigate("/home/app/" + id);
    }
  };

  useEffect(() => {
    const fetchRecentChat = async () => {
      setLoadingRecent(true);
      // await sleep(5000);
      try {
        const dataSubs = await getRecentPaging(paging);
        setTimeout(() => {
          if (dataSubs?.data?.length) {
            if (
              paging.limit === INIT_LIMIT ||
              (paging.limit !== INIT_LIMIT && paging.page === 1)
            ) {
              dispatch(
                chatAction.initRecentChatHandler({
                  recentChat: dataSubs.data,
                })
              );
            } else {
              dispatch(
                chatAction.recentChatHandler({
                  recentChat: dataSubs.data,
                })
              );
            }

            setPaging((prev) => ({
              ...prev,
              total: dataSubs.total,
              totalPages: calculatePage(dataSubs.total, prev.limit),
            }));
          }
        });

        setLoadingRecent(false);
      } catch (error) {
        setLoadingRecent(false);
      }
    };
    fetchRecentChat();
  }, [paging.page, paging.limit]);

  const showMore = () => {
    setPaging((prev) => ({
      ...prev,
      limit: LOADMORE_LIMIT,
    }));
  };
  const divRef = useRef();

  const handleScroll = () => {
    if (
      paging.limit === INIT_LIMIT ||
      (paging.limit !== INIT_LIMIT && paging.page >= paging.totalPages)
    ) {
      return;
    }
    const { scrollTop, scrollHeight, offsetHeight } = divRef.current;
    if (scrollTop + offsetHeight >= scrollHeight) {
      setPaging((prev) => ({
        ...prev,
        page: prev.page + 1,
        limit: LOADMORE_LIMIT,
      }));
    }
  };

  const handleCheckboxCheckAll = () => {
    if (!isChekedAll) {
      setSelectedItems(recentChat?.map((m) => m._id));
    } else {
      setSelectedItems([]);
    }

    setIsChekedAll((prevSelectedItems) => !prevSelectedItems);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
    setIsChekedAll(false);
  };

  const sideBarWidthHandler = () => {
    dispatch(uiAction.toggleSideBar());
  };

  useEffect(() => {
    const id = chatHistoryId || "";
    setIsActiveChat(id);
  }, [chatHistoryId]);

  const settingsHandler = (e) => {
    dispatch(uiAction.toggleSettings());
    if (e.view.innerWidth <= 960) {
      dispatch(uiAction.toggleSideBar());
    }
  };

  const showTutorial = () => {
    dispatch(uiAction.toggleTutorial());
  };

  const newChatHandler = () => {
    if (!isNewChat) {
      return;
    }
    dispatch(chatAction.setIsReplying(false));
    dispatch(chatAction.replaceChat({ chats: [] }));
    dispatch(chatAction.newChatHandler());
    dispatch(chatAction.chatHistoryIdHandler({ chatHistoryId: "" }));
    navigate("/home");
  };

  const icon = themeIcon();
  const sideBarWidthClass = isSidebarLong ? "side-bar-long" : "side-bar-sort";

  const handleDeleteRecentChat = (chatHistoryId) => {
    dispatch(deleteRecentChat(chatHistoryId));
  };

  const closeSelectMulti = async () => {
    setSelectedItems([]);
    setSelectMultiple(false);
  };

  const handleDeleteChats = async () => {
    if (!selectedItems.length) {
      return;
    }
    const ids = selectedItems;
    try {
      await deleteRecentChats(ids);

      // Clear selected items
      setSelectedItems([]);
      setSelectMultiple(false);
      dispatch(
        chatAction.removeRecentChatHandler({
          ids: ids,
        })
      );
    } catch (error) {}
  };

  const toggleDeletePopup = () => {
    if (!selectedItems.length) {
      return;
    }
    setIsPopupOpen(!isPopupOpen);
  };

  const rencentChats = useMemo(() => {
    if (!recentChat.length) return <></>;
    return (
      <>
        {recentChat.length &&
          recentChat.map((chat, index) => (
            <div
              className="w-full flex items-center"
              to={`/home/app/${chat._id}`}
              key={chat._id}
            >
              <div
                className={`${styles["recent-chat"]} ${
                  isActiveChat === chat._id ? styles["active-recent-chat"] : ""
                } whitespace-nowrap text-ellipsis`}
                onClick={(e) => {
                  itemRecentClick(chat._id);
                }}
              >
                {selectMultiple && (
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(chat._id)}
                    onChange={(e) => {}}
                    className="h-4 w-4 text-blue-600 cursor-pointer hover:opacity-75 translate hover:scale-110"
                  />
                )}
                <p>{chat.title.slice(0, 22)}</p>
                {!selectMultiple && (
                  <DropdownRecentChat
                    chatId={chat._id}
                    handleDeleteRecentChat={handleDeleteRecentChat}
                    setSelectMultiple={setSelectMultiple}
                  />
                )}
              </div>
            </div>
          ))}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentChat, selectedItems, isActiveChat, selectMultiple]);

  return (
    <div className={`${styles["sidebar-main"]} ${styles[sideBarWidthClass]}`}>
      <div className="w-full p-3 h-[60px] md:h-14">
        <div className={styles["menu-icon"]} onClick={sideBarWidthHandler}>
          <img src={commonIcon.menuIcon} alt="menu icon"></img>
        </div>
        {isSidebarLong && (
          <div
            onClick={newChatHandler}
            className="rounded-full flex items-center right-2 absolute size-10 justify-center cursor-pointer hover:bg-[var(--menuicon-hover-bg)]"
          >
            <img
              src={commonIcon.addtIcon}
              className={`size-5 ${
                !isNewChat ? "opacity-10" : "opacity-80 hover:opacity-50"
              }`}
              alt="plus icon"
              title="New Chat"
            ></img>
          </div>
        )}
      </div>

      {isSidebarLong && (
        <div className="flex w-[100%] p-3 md:mt-20 justify-between gap-2">
          {selectMultiple ? (
            <div className="px-1 flex items-center">
              <input
                title="Delete all recent chat"
                type="checkbox"
                checked={isChekedAll}
                onChange={handleCheckboxCheckAll}
                className="h-4 w-4 text-blue-600 cursor-pointer hover:opacity-75 translate hover:scale-110 mr-1"
              />
              {/* <span className="font-bold text-red-300">Delete All</span> */}
            </div>
          ) : (
            <p className="text-lg">Recent</p>
          )}
          {selectMultiple && (
            <div className="flex items-center space-x-3">
              <IconTrash
                onClick={toggleDeletePopup}
                className="rounded-full hover:bg-[var(--menuicon-hover-bg)] cursor-pointer w-6 h-6 opacity-65 hover:opacity-85 translate hover:scale-110 ml-3"
              />
              <IconClose
                onClick={closeSelectMulti}
                className="rounded-full hover:bg-[var(--menuicon-hover-bg)] cursor-pointer w-6 h-6 opacity-65 hover:opacity-85 translate hover:scale-110"
              />
            </div>
          )}
        </div>
      )}
      <div
        ref={divRef}
        onScroll={handleScroll}
        className={styles["recent-chat-section"]}
      >
        {!isSidebarLong && (
          <div
            onClick={newChatHandler}
            title="Click Here for New Chat"
            className={`${styles["pluc-icon"]} ${styles["new-plus-icon"]} mt-16`}
          >
            <img src={icon.plusIcon} alt="plus icon"></img>
          </div>
        )}
        {isSidebarLong && (
          <>
            <div className={`${styles["recent-chat-main"]} px-2 py-3`}>
              {rencentChats}
              <div className="px-3 w-full flex justify-center items-center">
                {paging.limit === INIT_LIMIT &&
                  paging.total > recentChat.length && (
                    <button
                      onClick={showMore}
                      className="text-gray-700 font-semibold hover:text-black px-3 w-full hover:bg-[var(--menuicon-hover-bg)] flex flex-row items-center text-lg md:text-sm cursor-pointer gap-2 rounded-md text-left"
                    >
                      <IconArrowDown />
                      <p className="p-2">Show more</p>
                    </button>
                  )}
                {loadingRecent && <IconSpinner />}
              </div>
            </div>
          </>
        )}
      </div>

      {isPopupOpen && (
        <ConfirmDeletePopup
          isOpen={isPopupOpen}
          togglePopup={toggleDeletePopup}
          handleDelete={handleDeleteChats}
        />
      )}
      <div className={styles["settings-section"]}>
        <div className={styles["settings"]} onClick={showTutorial}>
          <img src={commonIcon.tutorialIcon} alt="" className="w-5 h-5" />
          {isSidebarLong && <p>Tutorial</p>}
        </div>
        <div
          className={styles["settings"]}
          onClick={(e) => navigate("/frequently-asked-questions")}
        >
          <IconFAQ className="w-5 h-5" />
          {isSidebarLong && <p>FAQ</p>}
        </div>
        <div className={styles["settings"]} onClick={settingsHandler}>
          <img src={icon.settingsIcon} alt="settings icon"></img>
          {isSidebarLong && <p>Settings</p>}
        </div>

        {isSidebarLong ? (
          <button
            className={styles["BtnPremium"]}
            onClick={(e) => navigate("/subs")}
          >
            <svg
              viewBox="0 0 576 512"
              className={`${styles["logoIcon"]} size-6`}
            >
              <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
            </svg>
            GO PREMIUM
          </button>
        ) : (
          <div className={styles["upgrade"]} onClick={(e) => navigate("/subs")}>
            <svg
              viewBox="0 0 576 512"
              className={`${styles["logoIcon"]} size-6`}
            >
              <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
