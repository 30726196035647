import "./App.css";
import ChatSection from "./components/ChatSection/ChatSection";
import SettingSection from "./components/SettingSection/Setting";
import TutorialSection from "./components/Tutorial/TutorialPopup";
import Sidebar from "./components/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "./store/ui-gemini";
import { useEffect } from "react";
import UserDetails from "./components/UserDetails/UserDetails";

function App() {
  const dispatch = useDispatch();
  const settingsShow = useSelector((state) => state.ui.isSettingsShow);
  const isAdvanceGeminiPrompt = useSelector((state) => state.ui.isAdvanceShow);
  const isDark = useSelector((state) => state.ui.isDark);
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);

  const settingHandler = () => {
    if (settingsShow === true) {
      dispatch(uiAction.toggleSettings());
    }
    if (isAdvanceGeminiPrompt === true) {
      dispatch(uiAction.toggleAdvanceShow());
    }

    if (isUserDetails === true) {
      dispatch(uiAction.toggleUserDetailsShow());
    }
  };

  useEffect(() => {
    const getLocalTheme = localStorage.getItem("theme");
    const theme = getLocalTheme || "light";
    document.documentElement.setAttribute("data-theme", theme);
  }, [isDark]);

  useEffect(() => {
    const showUserPrompt = setInterval(() => {
      const isShowIntroAlready = localStorage.getItem("isIntroShow") || false;
      if (!isShowIntroAlready) {
        dispatch(uiAction.userIntroPromptHandler({ introPrompt: true }));
        localStorage.setItem("isIntroShow", true);
      }
    }, 2 * 1000);

    return () => clearInterval(showUserPrompt);
  }, [dispatch]);

  return (
    <div className="App">
      <Sidebar />
      <ChatSection />
      <SettingSection />
      <TutorialSection />
      {isUserDetails && isLogin && <UserDetails />}
      {isAdvanceGeminiPrompt && (
        <div onClick={settingHandler} className="bg-focus-dark"></div>
      )}
      {isUserDetails && isLogin && (
        <div onClick={settingHandler} className="bg-focus-dark"></div>
      )}
    </div>
  );
}

export default App;
